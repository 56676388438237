import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  width: 680px;
  height: 83px;
  position: absolute;
  opacity: 0;
  bottom: 0;
  margin-bottom: 250px;

  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 96px;
    right: 0;

    &.playball-outro {
      opacity: 0;
    }
  }

  canvas.ball-launcher {
    width: 100px;
    height: 160px;
    margin: auto;
    top: 230px;
    @media screen and (max-height: 1123px) {
      top: 115px;
    }
    left: -2px;
  }
`;